var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm0 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M12 10h-1a1 1 0 0 0 0 2h1v6a1 1 0 0 0 2 0v-6a2 2 0 0 0-2-2Z"
    }
  }), _vm._v(" "), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "6.5",
      "r": "1.5"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }